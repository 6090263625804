import "../css/ourwork.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"
import { Modal } from "antd";
import { motion } from 'framer-motion';
import { MdArrowOutward } from "react-icons/md";

const Poductionimages = [
  "/ourwork/Production/paradiso/paradisoII.jpg",
  "/ourwork/Production/paradiso/paradisoXI.jpg",
  "/ourwork/Production/paradiso/paradisoXIII.jpg",
  "/ourwork/Production/paradiso/paradisoXIV.jpg",
  "/ourwork/Production/paradiso/paradisoXIX.jpg",
  "/ourwork/Production/paradiso/paradisoXVII.jpg",
  "/ourwork/Production/paradiso/paradisoXX.jpg",
];

const images = [
  "/ourwork/photo/section/nofame-photo (10).jpg",
  "/ourwork/photo/section/nofame-photo (11).jpg",
  "/ourwork/photo/section/nofame-photo (4).jpg",
  "/ourwork/photo/section/nofame-photo (12).jpg",
  "/ourwork/photo/section/nofame-photo (13).jpg",
  "/ourwork/photo/section/nofame-photo (14).jpg",
  "/ourwork/photo/section/nofame-photo (15).jpg",
  "/ourwork/photo/section/nofame-photo (16).jpg",
  "/ourwork/photo/section/nofame-photo (17).jpg",
  "/ourwork/photo/section/nofame-photo (18).jpg",
  "/ourwork/photo/section/nofame-photo (19).jpg",
  "/ourwork/photo/section/nofame-photo (20).jpg",
  "/ourwork/photo/section/nofame-photo (21).jpg",
  "/ourwork/photo/section/nofame-photo.jpg",
  "/ourwork/photo/section/nofame-photo (1).jpg",
  "/ourwork/photo/section/nofame-photo (2).jpg",
  "/ourwork/photo/section/nofame-photo (3).jpg",
  "/ourwork/photo/section/nofame-photo (5).jpg",
  "/ourwork/photo/section/nofame-photo (6).jpg",
  "/ourwork/photo/section/nofame-photo (7).jpg",
  "/ourwork/photo/section/nofame-photo (8).jpg",
  "/ourwork/photo/section/nofame-photo (9).jpg",
];

// const videos = [
//   "/testing.mp4",
//   "/testing2.mp4",
//   // "/testing.mp4",
//   // "/testing2.mp4",
// ];

const categories = {
  "Music Festivals": [
    "/ourwork/Production/paradiso/paradisoII.jpg",
  ],
  "Corporate": [
    "/ourwork/Production/paradiso/paradisoII.jpg",

  ],
  "Sports": [
    "/ourwork/Production/paradiso/paradisoII.jpg",

  ],
  "Brands": [
    "/ourwork/Production/paradiso/paradisoII.jpg",

  ],
  "etc.": [
    "/ourwork/Production/paradiso/paradisoII.jpg",
  ],
};

const pageVariants = {
  initial: { y: "100%", opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 0.8 } },
  exit: { y: "-100%", opacity: 0, transition: { duration: 0.8 } },
}

function OurWork() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 1,
    infinite: true,
    speed: 8000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: false,
    cssEase: "linear",
    variableWidth: true,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // const [currentIndex, setCurrentIndex] = useState(0);

  // const nextVideo = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
  // };

  // const prevVideo = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
  // };

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (category) => {
    setSelectedCategory(category);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.hash) {
      const element = document.getElementById(location.hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location])

  return (
    <motion.div className="Ourwork" variants={pageVariants} initial="initial" animate="animate" exit="exit">
      <div className="Photo" id="Photogrphy">
        <h1>Photography</h1>
        <p>No Fame Agency provides expert photography services, capturing stunning visuals that tell compelling stories. Whether it's event coverage, commercial shoots, or artistic projects, the team focuses on composition, lighting, and detail to produce high-quality images. Every shot is carefully curated to reflect the essence of the subject, ensuring visually striking and impactful results.</p>
        <div>
          <Swiper
            modules={[Navigation, Autoplay, Pagination]}
            navigation
            pagination={{
              clickable: true,
              dynamicBullets: true, // เปิดใช้งาน dynamic bullets
              dynamicMainBullets: 1, // ให้แสดง 3 จุดเสมอ (จุดกลางเป็น active)
            }}
            loop={true}
            centeredSlides={true}
            autoplay={{ delay: 1500 }}
            style={{ overflow: "hidden", position: "relative", paddingBottom: "30px" }}
            breakpoints={{
              600: { slidesPerView: 1, spaceBetween: 5, pagination: false },
              1024: { slidesPerView: 4, spaceBetween: 10 },
            }}
          >
            {images.map((src, index) => (
              <SwiperSlide key={index} className="carousel-item">
                <img src={src} alt={`${selectedCategory} ${index + 1}`} className="carousel-image" />
              </SwiperSlide>
            ))}
          </Swiper>


        </div>

        {/* Modal for selected category */}
        <Modal open={isModalOpen} onCancel={closeModal} footer={null} centered>
          <h2>{selectedCategory}</h2>
          <div className="modal-gallery">
            {selectedCategory &&
              categories[selectedCategory].map((src, index) => (
                <img key={index} src={src} alt={`${selectedCategory} ${index + 1}`} className="modal-image" />
              ))}
          </div>
        </Modal>
      </div>
      <div className="Video" id="Videogrphy">
        <h1>Videography</h1>
        <div className="video-container">
          {/* <video className="background-videography" autoPlay loop muted key={videos[currentIndex]}>
            <source src={videos[currentIndex]} type="video/mp4" />
          </video>
          <button className="arrow left" onClick={prevVideo}>
            <LeftOutlined />
          </button>
          <button className="arrow right" onClick={nextVideo}>
            <RightOutlined />
          </button> */}
          <p>For videography, NF Entertainment specializes in professional filming production for a wide range of projects, including movies, music videos, and commercials. The team combines creative vision with technical expertise to craft high-quality films, ensuring every frame enhances the storytelling. From pre-production planning to post-production editing, every step is meticulously handled to deliver visually engaging and immersive content.</p>
          <iframe

            src="https://www.youtube.com/embed/-oXf4r4ypLw?si=9h2VGt9Z51GHXqTu"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <div className="Production" id="Production">
        <h1>Event Production</h1>
        <p>NF Entertainment curates, produces, and manages immersive events, handling everything from concept development to full-scale execution. Beyond client projects, NF Entertainment also creates its own signature event, Paradiso Carnival—a festival that blends music, art, and storytelling to push creative boundaries.</p>
        <div className="production-section">
          <img src="/logo/paradiso_yellow.png" alt="paradiso carnival" width={280} />
          <video className="background-video-ourwork" autoPlay
            loop
            muted
            playsInline
            controlsList="nodownload"
            onContextMenu={(e) => e.preventDefault()}
            preload="auto"
            title="Paradiso Carnival 2025 - The Best Music Festival in Bangkok"
          >
            <source src="/ourwork/Production/paradiso/video/paradiso-website-.mp4" type="video/mp4" />
          </video>
          <Swiper loop={true}
            modules={[Autoplay]}
            autoplay={{ delay: 3000 }}
            className="carousel"
            slidesPerView={4}
            breakpoints={{
              320: { slidesPerView: 1, spaceBetween: 5 },
              768: { slidesPerView: 2, spaceBetween: 10 },
              1024: { slidesPerView: 4, spaceBetween: 10 },
            }}
          >
            {Poductionimages.concat(Poductionimages).map((srcproduction, indexproduction) => (
              <SwiperSlide key={indexproduction} className="carousel-item">
                <img
                  src={srcproduction}
                  alt={`Slide ${indexproduction + 1}`}
                  className="carousel-image"
                  style={{ width: "auto", height: "200px", objectFit: "cover" }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="text-production">
            <p>
              Paradiso Carnival blends the vibrant energy of a circus and carnival with the immersive experience of an electronic music festival.
              Centered around a jungle vibe, each edition will introduce a unique sub-theme. Recognizing a gap in the Asian market for such an event,
              it aims to deliver a unique and exhilarating experience. Featuring top international tech-house DJs, stunning decorations,
              captivating performers, dazzling costumes, confetti, props, and interactive games, Paradiso Carnival promises an unforgettable celebration for attendees.
              {" "}<a
                href="https://www.paradisocarnival.com/"
                target="_blank"
                rel="noreferrer"
                className="more-link"
              >
                More <MdArrowOutward className="more-icon" />
              </a>
            </p>

          </div>
        </div>
      </div>

    </motion.div>
  );
}

export default OurWork;
