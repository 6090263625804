import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Layout from "./Layout";
import About from "./pages/About";
import Services from "./pages/Services";
import OurWork from "./pages/OurWork";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Register from "./pages/Register"
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import DonationFeb2025 from "./components/blog-page/DonationFeb2025";
import {
  initGA,
  trackPageView,
  trackDevice,
  trackIP,
  trackUserDemographics,
  trackUserLogin,
} from "./utils/analytics";

const GA_MEASUREMENT_ID = "G-1346X5NJ9F";

function AnimatedRoutes() {
  const location = useLocation();
  const isAuthPage = location.pathname === "/login" || location.pathname === "/register";
  useEffect(() => {
    window.scrollTo(0, 0);
    initGA(GA_MEASUREMENT_ID);
    trackPageView(location.pathname + location.search);
    trackDevice();
    trackIP();

  }, [location.pathname]);

  return (
    <AnimatePresence mode="wait">
      {isAuthPage ? (
        <Routes location={location} key={location.pathname}>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      ) : (
        <Layout>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Home />} />
            <Route path="/blog/Donation-feb-2025" element={<DonationFeb2025 />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/services" element={<Services />} />
            <Route path="/our-work" element={<OurWork />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Layout>
      )}
    </AnimatePresence>
  );
}

function App() {
  return (
    <Router>
      <AnimatedRoutes />
    </Router>
  );
}

export default App;