import "../css/services.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion } from 'framer-motion';

const pageVariants = {
  initial: { y: "100%", opacity: 0 },
  animate: { y: 0, opacity: 1, transition: { duration: 0.8 } },
  exit: { y: "-100%", opacity: 0, transition: { duration: 0.8 } },
}

function Services() {
  
  return (
    <motion.div className="Services" variants={pageVariants} initial="initial" animate="animate" exit="exit">
      <h1>Services</h1>
      <div className="service-nofame">
        <div className="service-nofame-1">
          <span className="nofame-text">No Fame Agency</span> {" "}
          is an international media coverage agency specializing in professional <b>photo</b> and <b>video</b> content for events of all sizes, from music festivals to corporate gatherings. With same-day delivery and expertise in visual storytelling, No Fame helps brands enhance engagement and visibility. Partnering with top industry names, they use innovative technology to create high-quality content that captures each event’s essence and extends its reach worldwide. Trusted by event organizers, marketers, and PR teams, No Fame delivers reliable, impactful media solutions that bring every vision to life.
        </div>
        <div className="service-nofame-2">
          <h4 className="animated-title">Media Coverage</h4>
          <p> focuses on event execution and content creation, <i>No Fame Agency</i> , our international media division, specializes in professional event coverage. <i>No Fame Agency</i> captures live experiences through high-quality photography and videography, delivering fast-turnaround content for social media, marketing, and post-event storytelling.</p>
        </div>
      </div>
      <hr />
      <div className="service-nf">
        <div className="service-nf-1">
          <b>NF Entertainment</b> is the <i>event production</i> division of No Fame Agency, specializing in curating and managing immersive event experiences. From concept development to execution, NF Entertainment handles all aspects of event production, ensuring seamless logistics, creative direction, and high-quality delivery.
          As the driving force behind <i>Paradiso Carnival</i>, NF Entertainment brings visionary ideas to life, crafting unique atmospheres that captivate audiences. By combining industry expertise with cutting-edge production techniques, the division enhances the overall event experience, making each project a standout success.
          With a commitment to innovation and excellence, NF Entertainment extends No Fame Agency’s mission beyond media coverage, providing comprehensive event solutions that blend creativity, technology, and flawless execution. Whether producing large-scale festivals or exclusive brand activations, NF Entertainment transforms ideas into unforgettable live experiences.
        </div>
        <div className="service-nf-2">
          <div className="service-nf-Event">
            <h4 className="animated-title">Event Production
            </h4>
            <p>We curate, produce, and manage immersive events, handling everything from concept development to full-scale execution. Beyond client projects, we also create our own signature event, Paradiso Carnival a festival that blends music, art, and storytelling to push creative boundaries.</p>
          </div>
          <div className="service-nf-Content">
            <h4 className="animated-title">Content Creation
            </h4>
            <p>Our team specializes in video editing and motion graphics, transforming raw footage into polished, high-quality visuals. We produce engaging content tailored for event recaps, promotional materials, artist showcases, and branded media, enhancing storytelling through cinematic visuals.</p>

          </div>
          {/* <div className="service-nf-CUBORO">
            <h4>CUBORO <span><MdArrowOutward /></span></h4>
            <p>We serve as the B2B representative for CUBORO, integrating its marble run system into corporate events, team-building activities, and educational programs. We create immersive experiences that foster creativity, collaboration, and problem-solving, making CUBORO a valuable tool beyond play.</p>
          </div> */}

        </div>
      </div>
    </motion.div>
  );
}
export default Services;
