import React from "react";
import { Layout } from "antd";
import HeaderBar from "./components/HeaderBar";
import { Footer } from "antd/es/layout/layout";
import FooterBar from "./components/FooterBar";

const { Header, Content } = Layout;

function MainLayout({ children }) {

  return (
    <Layout>
      <Header>
        <HeaderBar />
      </Header>
      <Content>
        {children}
      </Content>
      <Footer>
        <FooterBar />
      </Footer>
    </Layout>
  );
}

export default MainLayout;