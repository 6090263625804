import { Menu, Drawer, Button } from "antd";
import { Link, useLocation } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { RxPerson } from "react-icons/rx";
import { useState, useEffect } from "react";
import "../css/header.css";

function Header() {
  const [isMobile, setIsMobile] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const location = useLocation(); 

  const showDrawer = () => setDrawerVisible(true);
  const closeDrawer = () => setDrawerVisible(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const menuItems = (
    <>
      {[
        { key: "home", path: "/", label: "Home" },
        // { key: "about", path: "/about", label: "About" },
        // { key: "services", path: "/services", label: "Services" },
        { key: "our-work", path: "/our-work", label: "Our Work" },
        { key: "blog", path: "/blog", label: "Blog" },
        { key: "contact", path: "/contact", label: "Contact" }
      ].map(({ key, path, label }) => (
        <Menu.Item key={key} className={location.pathname === path ? "active-menu-item" : ""}>
          <Link to={path}  aria-label={label} onClick={closeDrawer}>{label}</Link>
        </Menu.Item>
      ))}
    </>
  );

  return (
    <div className="header-container">
      {!isMobile ? (
        <Menu className="custom-menu" mode="horizontal">
          <div className="menu-left-web">
            <Menu.Item key="logo">
              <Link to="/">
                <img src="/logo/logo.png" alt="Logo" width={40} />
              </Link>
            </Menu.Item>
          </div>

          <div className="menu-center-group">{menuItems}</div>

          <div className="menu-right-web">
            <Menu.Item key="login">
              <Link to="/login"><RxPerson /></Link>
            </Menu.Item>
          </div>
        </Menu>
      ) : (
        <>
          <div className="custom-menu-phone">
            <div className="menu-left">
              <Button
                icon={<MenuOutlined style={{ fontSize: '20px', color: "white" }} />}
                onClick={showDrawer}
                className="burger-button"
              />
            </div>

            <div className="logo-mobile">
              <Link to="/">
                <img src="/logo/logo.png" alt="Logo" width={40} />
              </Link>
            </div>

            <div className="menu-right-phone">
              <Link to="/login"><RxPerson /></Link>
            </div>
          </div>

          <Drawer
            placement="left"
            onClose={closeDrawer}
            open={drawerVisible}
            destroyOnClose
            className="custom-drawer"
          >
            <Menu mode="vertical">{menuItems}</Menu>
          </Drawer>
        </>
      )}
    </div>
  );
}

export default Header;
