import { useState } from "react";
import { Button, Input, Form, Card } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useNavigate } from "react-router-dom";
import "../css/login.css";
import { motion } from 'framer-motion';

function Register() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = (values) => {
        setLoading(true);
        console.log("Login Data:", values);
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    };

    const pageVariants = {
        initial: { y: "100%", opacity: 0 },
        animate: { y: 0, opacity: 1, transition: { duration: 0.8 } },
        exit: { y: "-100%", opacity: 0, transition: { duration: 0.8 } },
    };

    return (
        <motion.div
            variants={pageVariants}
            initial="initial"
            animate="animate"
            exit="exit" className="login-container">
            <Card className="login-card">
                <div className="login-head" >
                    <span >
                        <ArrowLeftOutlined onClick={() => navigate("/")} />
                    </span>
                    <h1 className="login-subtitle">Sign in to your account</h1>
                </div>

                <Form layout="vertical" onFinish={handleLogin} className="login-form">
                    <Form.Item
                        name="name"
                        rules={[{ required: true, message: "Please enter your name" }]}
                    >
                        <Input placeholder="Enter your name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: "Please enter your email" }]}
                    >
                        <Input placeholder="Enter your email" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: "Please enter your password" }]}
                    >
                        <Input.Password placeholder="Enter your password" />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        rules={[{ required: true, message: "Please confirm your password" }]}
                    >
                        <Input.Password placeholder="Confirm your password" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block loading={loading}>
                            <h3>— Sign in —</h3>
                        </Button>
                    </Form.Item>
                </Form>

                <h3>
                    Back to <Link to="/login">Login</Link>
                </h3>
            </Card>
        </motion.div>
    );
}

export default Register;
