import React, { useEffect, useRef, useState } from "react";
import "../css/about.css";
import { motion } from 'framer-motion';


function About() {

  const pageVariants = {
    initial: { y: "100%", opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { duration: 0.8 } },
    exit: { y: "-100%", opacity: 0, transition: { duration: 0.8 } },
  }


  const textRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.5 }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      window.scrollTo(0, 0);
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };

  }, []);
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="about-pic-bg">
        <img src="/about/nofame-BTS.png" />
      </div>
      <div className="About">

      </div>

    </motion.div>
  )
}
export default About;
