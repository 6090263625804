import { Link } from "react-router-dom";
import { useEffect } from "react";
import "../css/blog.css";
import { motion } from 'framer-motion';
import { MdArrowOutward } from "react-icons/md";

function Blog() {

  const pageVariants = {
    initial: { y: "100%", opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { duration: 0.8 } },
    exit: { y: "-100%", opacity: 0, transition: { duration: 0.8 } },
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit" className="Blog">
      <div className="Blog-pic-main">
        <img src="/blog/donationfeb2025.JPG" alt="donation NF"></img>
      </div>
      <div className="Blog-text">
        <div className="small-spacing">
          <p>6 February 2025</p>
          <h3>NF Entertainment Thai Team Gives Back to the Community</h3>
        </div>

        <div className="Blog-descript">
          <p>At NF Entertainment, we believe in using our platform not only to create unforgettable experiences but also to make a meaningful impact on the communities around us. With this spirit, our Thai team has come together to support underprivileged children by donating toys and decorative props from Paradiso Carnival to the Foundation For Slum Child Care.
            {" "}<Link
              to="/blog/Donation-feb-2025"
              className="more-link"
            >
              More <MdArrowOutward className="more-icon" />
            </Link>
            </p>
        </div>
      </div>
    </motion.div>
  )
}
export default Blog;
