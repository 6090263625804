export const initGA = (measurementId) => {
    if (!window.gtag) {
      console.warn("gtag.js ");
      return;
    }
    window.gtag("config", measurementId);
  };
  
  export const trackPageView = (url) => {
    if (!window.gtag) return;
    window.gtag("event", "page_view", {
      page_path: url,
    });
  };
  
  export const trackEvent = (action, category, label, value) => {
    if (!window.gtag) return;
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  };
  
  export const trackDevice = () => {
    const userAgent = navigator.userAgent;
    let device = "Desktop";
    if (/Mobi|Android/i.test(userAgent)) {
      device = "Mobile";
    } else if (/Tablet|iPad/i.test(userAgent)) {
      device = "Tablet";
    }
    window.gtag("event", "device_info", { device, userAgent });
  };
  
  export const trackIP = async () => {
    try {
      const res = await fetch("https://api64.ipify.org?format=json");
      const data = await res.json();
      window.gtag("event", "user_ip", { ip_address: data.ip });
    } catch (error) {
      console.error("Error fetching IP:", error);
    }
  };
  
  export const trackUserDemographics = (age, gender) => {
    window.gtag("set", "user_properties", {
      age_group: age,
      gender: gender,
    });
  };
  
  export const trackUserLogin = (userId, email) => {
    window.gtag("set", { user_id: userId, user_email: email });
  };
  