import "../css/contact.css";
import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import ReCAPTCHA from "react-google-recaptcha";

function Contact() {
  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [captchaValue, setCaptchaValue] = useState(null);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   if (!captchaValue) {
  //     notification.error({
  //       message: "Verification Failed",
  //       description: "Please complete the CAPTCHA verification.",
  //     });
  //     return;
  //   }
  //   alert("Your message has been sent!");
  //   const { name, message } = formData;
  //   const subject = encodeURIComponent(`Contact us from : ${name}`);
  //   const body = encodeURIComponent(`${message}`);

  //   window.location.href = `mailto:contact@nofame.agency?subject=${subject}&body=${body}`;
  //   setFormData({ name: "", email: "", message: "" });
  //   setCaptchaValue(null);
  // };


  const pageVariants = {
    initial: { y: "100%", opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { duration: 0.8 } },
    exit: { y: "-100%", opacity: 0, transition: { duration: 0.8 } },
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit" className="contact-page">

      <div className="contact-content">
        <div className="cantact-form-2">
          <div className="contact-text-first">
            <h2>Let's Collaborate</h2>
            <p>Your world. No Fame's perspective. Magic happens</p>
            {/* <h2>
              <b>No Fame Agency</b> : We Capture. You Shine.
            </h2>
            <p>Let's work together.</p> */}
          </div><br />

          <div className="contact-form-container">
            <form
              action="https://formsubmit.co/chularat@nofame.agency"
              method="POST"
              className="contact-form-2"
            >
              <div className="form-group-2">
                <label style={{ display: "flex" }}>
                  Name <label style={{ color: "#871212" }}>*</label>
                </label>
                <input type="text" name="name" required />
              </div>

              <div className="form-group-2">
                <label style={{ display: "flex" }}>
                  Email <label style={{ color: "#871212" }}>*</label>
                </label>
                <input type="email" name="email" required />
              </div>

              <div className="form-group-2">
                <label style={{ display: "flex" }}>
                  Message <label style={{ color: "#871212" }}>*</label>
                </label>
                <textarea name="message" rows="5" required></textarea>
              </div>

              <div className="form-group">
                <ReCAPTCHA
                  sitekey="6LdNw_EqAAAAAMqVjkgT9P3Qbs2VSsCNKFQ_lrQh"
                  onChange={handleCaptchaChange}
                />
              </div>
              <br />

              <button type="submit" className="send-button">
                — SEND —
              </button>
            </form>
          </div>


        </div>
      </div>
    </motion.div>
  );
}

export default Contact;
