import React, { useState, useEffect } from "react";
import "../css/App.css";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import { trackEvent } from "../utils/analytics";
import { Image, notification } from "antd";
import ReCAPTCHA from "react-google-recaptcha";

function Home() {
  const images = [
    "/home/section-ourwork/nofame-photo-bolt.jpg",
    "/home/section-ourwork/nofame-photo-city.jpg",
    "/home/section-ourwork/nofame-photo-corperate.jpg",
    "/home/section-ourwork/nofame-photo-sport.jpg",
    "/home/section-ourwork/nofame-photo.jpg",
    "/home/section-ourwork/festival-moon.jpg",
    "/home/section-ourwork/korean-girl.jpg",
    "/home/section-ourwork/home-desert.jpg",
  ];

  const ContactImages = [
    "/home/section-contact/nofame-team2.jpg",
    "/home/section-contact/nofame-team.jpg",
    "/home/section-contact/nofame-team3.jpg",
    "/home/section-contact/nofame-team4.jpg",
  ];

  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      notification.error({
        message: "Verification Failed",
        description: "Please complete the CAPTCHA verification.",
      });
      return;
    }

    alert("Your message has been sent!");
    const { name, message } = formData;
    const subject = encodeURIComponent(`Contact us from : ${name}`);
    const body = encodeURIComponent(`${message}`);

    window.location.href = `mailto:contact@nofame.agency?subject=${subject}&body=${body}`;
    setFormData({ name: "", email: "", message: "" });
    setCaptchaValue(null);
    trackEvent("click", "button", "contact_us", 1);
  };

  const pageVariants = {
    initial: { y: "100%", opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { duration: 0.8 } },
    exit: { y: "-100%", opacity: 0, transition: { duration: 0.8 } },
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <video className="background-video" autoPlay loop muted>
        <source src="/nofame-main.mp4" type="video/mp4" />
      </video>

      <div className="media">
        <h1>Media Coverage Agency</h1>
      </div>

      <div className="Home">

        <div className="section-we">
          <div className="text-section">
            <div>
              <h1>WE ARE</h1>
              <h2>International Media Coverage Agency</h2>
            </div>

            <img src="/home/section-1/media_team(2).jpg" alt="BTS Camera Shot 1" />
          </div>
          <div className="image-section">
            <img src="/home/section-1/media_team.jpg" alt="BTS Camera Shot 2" />
          </div>
          <div className="text-section2">
            <img src="/home/section-1/media_team(3).jpg" alt="BTS Camera Shot 3" />
            <h2>
              We capture stunning visuals with a focus on composition, lighting, and detail. From event coverage to commercial and artistic projects, every shot is carefully crafted to tell a compelling story and create impactful results. </h2>
          </div>
        </div>

        {/* <div className="section-service">
          <div className="services">
            <h1>Services</h1>
          </div>
          <div className="services-menu">
            <Link to="/our-work#Photogrphy">
              <h2>Photography</h2>
              <p>
                We capture stunning visuals with a focus on composition, lighting, and detail. From event coverage to commercial and artistic projects, every shot is carefully crafted to tell a compelling story and create impactful results.
              </p>
            </Link>
          </div>
          <div className="services-menu">
            <Link to="/our-work#Videogrphy">
              <h2>Videography</h2>
              <p>
                The team specializes in professional videography, producing high-quality films for movies, music videos, and commercials. They handle every step, from planning to editing, to create visually engaging content that enhances storytelling.
              </p>
            </Link>
          </div>
          <div className="services-menu">
            <Link to="/our-work#Production">
              <h2>Event Production</h2>
              <p>
                NF Entertainment curates, produces, and manages immersive events, handling everything from concept development to full-scale execution. Beyond client projects, NF Entertainment also creates its own signature event, Paradiso Carnival—a festival that blends music, art, and storytelling to push creative boundaries.
              </p>
            </Link>
          </div>
        </div> */}

        {/* <div className="section-upcoming">
          <div className="text-upcoming">
            <h1>Upcoming events</h1>
          </div>
          <div className="pic-upcoming" >
            <img src="/home/section-upcoming/paradiso.jpg" alt="event" onClick={() => window.location.href = "https://www.paradisocarnival.com"} />
            <div className="event-name">
              <h3>Paradiso Carnival Mar. 2025</h3>
            </div>
          </div>
        </div> */}

        <div className="section-ourwork">
          <div className="gallery-section">
            <h2>Our Work</h2>
            <div className="gallery-grid2">
              {images.map((src, index) => (
                <div key={index} className="gallery-item2">
                  <Image src={src} alt={`Gallery ${index + 1}`} />
                </div>
              ))}
            </div>

            <div className="more-section">
              <Link to="/our-work">— MORE —</Link>
            </div>
          </div>
        </div>

        <div className="section-contact">
          <div className="contact-pic">
            {ContactImages.map((src, index) => (
              <div key={index} className={`gallery-item item-${index + 1}`}>
                <img src={src} alt={`Gallery ${index + 1}`} />
              </div>
            ))}
          </div>

          <div className="contact-form">
            <div className="contact-text-first">
              {/* <h1>
                Click ‧ Connect ‧ Fame

              </h1> */}
              <h2>Let's Create Together</h2>
              <p>Your world. Our perspective. Magic in the making</p>

            </div>

            <div className="contact-form-container">
              <form
                action="https://usebasin.com/f/4ea50500a8b3" method="POST" enctype="multipart/form-data" id="form"
                className="contact-form-2"
              >
                <div className="form-group">
                  <label style={{ display: "flex" }} for="name">
                    Name <label style={{ color: "#871212" }}>*</label>
                  </label>
                  <input type="text" name="name" required />
                </div>

                <div className="form-group">
                  <label style={{ display: "flex" }} for="email">
                    Email <label style={{ color: "#871212" }}>*</label>
                  </label>
                  <input type="email" name="email" required />
                </div>

                <div className="form-group">
                  <label style={{ display: "flex" }} name="message">
                    Message <label style={{ color: "#871212" }}>*</label>
                  </label>
                  <textarea name="message" rows="5" required></textarea>
                </div>

                <div className="form-group">
                  <ReCAPTCHA
                    className="captcha"
                    sitekey="6LdNw_EqAAAAAMqVjkgT9P3Qbs2VSsCNKFQ_lrQh"
                    onChange={handleCaptchaChange}
                  />
                </div>
                <br />

                <button type="submit" className="send-button">
                  — SEND —
                </button>
              </form>
            </div>

          </div>
        </div>
      </div>

    </motion.div>
  );
}

export default Home;
